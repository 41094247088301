"use strict";
import Vue from "vue";
import VueRx from "vue-rx";
import i18n from "./i18n";
import { init as sentryVueInit, BrowserTracing, Replay, vueRouterInstrumentation } from "@sentry/vue";
import { init as sentryElectronInit } from "@sentry/electron/renderer";
import { store } from "./store";
import router from "./router";
import App from "./App";
import vuetify from "./plugins/vuetify";
import VueCrudX from "ext-lib/webpacked/VueCrudX";
import VueCrudXDashboard from "ext-lib/webpacked/VueCrudXDashboard";
import VueCrudXMuster from "ext-lib/webpacked/VueCrudXMuster";
import TimePicker from "ext-lib/webpacked/TimePicker.vue";
import DatePicker from "ext-lib/webpacked/DatePicker.vue";
import FileUpload from "ext-lib/webpacked/FileUpload.vue";
import VueColumnsResizableVuetify from "vue-columns-resizable-vuetify";
import EventBus from './event-bus';
import Alert from './components/Alert.vue';

import { APP_VERSION, API_URL } from "@/config";
import { SENTRY_DSN, SENTRY_ENV, SENTRY_TRACERATE, SENTRY_REPLAYRATE, SENTRY_ERR_REPLAYRATE } from "@/config";

Vue.prototype.$EventBus = EventBus;
Vue.component('alert', Alert);

Vue.config.productionTip = false;
Vue.use(VueRx);
Vue.use(VueColumnsResizableVuetify);

Vue.component("app-date-picker", DatePicker);
Vue.component("app-time-picker", TimePicker);
Vue.component("app-file-upload", FileUpload);
Vue.component("vue-crud-x", VueCrudX);
Vue.component("vue-crud-x-dashboard", VueCrudXDashboard);
Vue.component("vue-crud-x-muster", VueCrudXMuster);


if( SENTRY_DSN ){
  let sentryCfg = {
    Vue,
    dsn: SENTRY_DSN,
    release: APP_VERSION,
    environment: SENTRY_ENV,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: vueRouterInstrumentation(router),
      }),
      new Replay(),
    ],
    tracesSampleRate: SENTRY_TRACERATE,
    tracePropagationTargets: ["localhost", API_URL],
    replaysSessionSampleRate: SENTRY_REPLAYRATE,
    replaysOnErrorSampleRate: SENTRY_ERR_REPLAYRATE,
  };

  if (window && window.electronAPI && (typeof window.electronAPI != "undefined")){
    sentryElectronInit(sentryCfg, sentryVueInit);
  }else{
    sentryVueInit(sentryCfg);
  }
}

/* eslint-disable no-new */
export const app = new Vue({
  el: "#app",
  i18n,
  vuetify,
  router,
  store,
  render: h => h(App)
});
