// -- This file generates the configuration object used throughout the application.
// -- Mostly env variables are checked and defaults are inserted for missing values.
// -- NB: dotenv is not called here explicitly because Vue consumes .env by itself
// --     and inserts any variable beginning with 'VUE_APP_' into process.env

// Calculation of API URL (w/special conditions for Heroku
//     VUE_APP_HEROKU_ values are injected through vue.config.js
let API_URL1 = process.env.VUE_APP_API_URL + ':' + process.env.VUE_APP_API_PORT ;

if (process.env.VUE_APP_IS_HEROKU && process.env.VUE_APP_IS_HEROKU === 'true') {
  const herokuFEAppName = process.env.VUE_APP_HEROKU_APP_NAME ? process.env.VUE_APP_HEROKU_APP_NAME : '' ;
  const herokuAPIAppName = herokuFEAppName.replace('fortifid-360-fe', 'fortifid-360-api');
  API_URL1 = `https://${herokuAPIAppName}.herokuapp.com`;
}
else {
  API_URL1 = process.env.VUE_APP_API_URL + ':' + process.env.VUE_APP_API_PORT ;
}

let config = {
  API_URL: API_URL1,
  RECAPTCHA_KEY: process.env.VUE_APP_RECAPTCHA_KEY || "",

  // Use localStorage / sessionStorage
  HTTPONLY_TOKEN: false, // true, false use HTTPONLY_TOKEN for more security, but needs same domain
  WITH_CREDENTIALS: false, // true = use same origin, false = cors

  PAGESIZE: process.env.VUE_APP_PAGESIZE || 20,
  PAGESIZE_OPTS:
    process.env.VUE_APP_PAGESIZE_OPTS &&
    process.env.VUE_APP_PAGESIZE_OPTS.length
      ? JSON.parse(process.env.VUE_APP_PAGESIZE_OPTS)
      : [4, 8, 10],

  APP_VERSION: process.env.VUE_APP_VERSION || "", // VUE_APP_VERSION is inject through setup in vue.config.js

  // Sentry config; feature is disabled if not provided
  SENTRY_ENV: process.env.VUE_APP_SENTRY_ENV || "",
  SENTRY_DSN: process.env.VUE_APP_SENTRY_DSN || "",
  SENTRY_TRACERATE: parseFloat(process.env.VUE_APP_SENTRY_TRACERATE) || 0.0,
  SENTRY_REPLAYRATE: parseFloat(process.env.VUE_APP_SENTRY_REPLAYRATE) || 0.0,
  SENTRY_ERR_REPLAYRATE: parseFloat(process.env.VUE_APP_SENTRY_ERR_REPLAYRATE) || 0.0
};

export const {
  API_URL,
  RECAPTCHA_KEY,
  HTTPONLY_TOKEN,
  WITH_CREDENTIALS,
  PAGESIZE,
  PAGESIZE_OPTS,
  APP_VERSION,
  SENTRY_ENV,
  SENTRY_DSN,
  SENTRY_TRACERATE,
  SENTRY_REPLAYRATE,
  SENTRY_ERR_REPLAYRATE
} = config;
