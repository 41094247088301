<template>
  <transition name="fade">
      <v-dialog v-model="isVisible" persistent width="unset">
      <div class="popup-modal" v-if="isVisible">
          <div class="window">
              <slot></slot>
          </div>
      </div>
      </v-dialog>
  </transition>
</template>

<script>
export default {
    name: 'PopupModal',

    data: () => ({
        isVisible: false,
    }),

    methods: {
        open() {
            this.isVisible = true
        },

        close() {
            this.isVisible = false
        },
    },
}
</script>