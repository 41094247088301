<template>
  <v-autocomplete
    solo
    flat
    clearable
    v-model="model"
    :items="allFacilities"
    item-text="Name"
    item-value="id"
    label="Select a Facility"
    hide-details
    prepend-inner-icon="mdi-magnify"
    placeholder="Start typing to search facilities..."
    @change="(id) => $emit('setFacility', id)">
  </v-autocomplete>
</template>

<script>

export default {
  name: "FacilitySearch",
  props: ["allFacilities"],
  data: () => ({
    model: null,
  }),
  methods: {},
  watch: {
    allFacilities(newVal) {
      if (newVal.length === 1) {
        this.model = newVal[0].id;
        this.$store.commit("changeActiveFacility", newVal[0].id);
        this.$emit('setFacility', newVal[0].id)
      }
    },
  },
  mounted() {},
}
</script>