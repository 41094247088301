<!--
  Alert Component

  This Vue component is responsible for displaying alerts on the screen.
  Alerts can be triggered from anywhere in the application using the EventBus.
  When an alert is shown, it can automatically dismiss itself after a certain duration.

  Usage:
  - To show an alert, emit the 'show-alert' event on the EventBus from any component.

    Examples:

    this.$EventBus.$emit('show-alert', {
      message: 'This is a success alert message',
      type: 'success',
      enableTimeout: false // optional! default is true
    });

    this.$EventBus.$emit('show-alert', {
      message: 'This is an error alert message',
      type: 'error',
    });

    this.$EventBus.$emit('show-alert', {
      message: 'This is an info alert message',
      type: 'info',
    });

    this.$EventBus.$emit('show-alert', {
      message: 'This is a warning alert message',
      type: 'warning',
    });

    You can customize the message and type for each alert.
    Colors for success, error, info, warning are set in the Vuetify theme at frontend/src/plugins/vuetify.js

  - The alerts will be displayed in a fixed container at the center of the screen.
  - Alerts are automatically removed after 5 seconds by default(configurable, pass 'enableTimeout: false' to disable).
  - Alerts can also be dismissed manually by the user.


  To use alerts outside of Vue components, import the EventBus and emit the event without the this keyword.

  Example:

  import { EventBus } from '../event-bus';

  EventBus.$emit('show-alert', {
    message: `Test alert.`,
    type: 'info',
    enableTimeout: false
  });
-->

<template>
  <!-- The container for displaying alerts -->
  <div class="alert-container">
    <!-- Loop through alerts and create v-alert components -->
    <v-alert
      v-for="alert in alerts"
      :key="alert.id"
      :type="alert.type"
      dismissible
      class="alert-item"
    >
      {{ alert.message }} <!-- Display the alert message -->
    </v-alert>
  </div>
</template>

<script>
export default {
  data() {
    return {
      alerts: [], // Array to store alert objects
      enableTimeout: true,
      nextAlertId: 1, // ID counter for unique alerts
      timeout: 5000 // Timeout in milliseconds
    };
  },
  methods: {
    showNewAlert({ message, type, enableTimeout=true }) {
      this.enableTimeout = enableTimeout;
      const id = this.nextAlertId++;
      this.alerts.push({ id, message, type });

      if(this.enableTimeout) {
        // Automatically remove the alert after a certain duration
        setTimeout(() => {
          this.removeAlert(id);
        }, this.timeout);
      }
    },
    removeAlert(id) {
      // Find and remove an alert by its ID
      const index = this.alerts.findIndex(alert => alert.id === id);
      if (index !== -1) {
        this.alerts.splice(index, 1);
      }
    },
  },
  created() {
    // Listen for the 'show-alert' event and call showNewAlert method
    this.$EventBus.$on('show-alert', this.showNewAlert);
  },
};
</script>

<style>
.alert-container {
  /* Fixed positioning to display alerts in the center of the screen */
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center vertically and horizontally */
}

.alert-item {
  width: 100%;
  margin-bottom: 10px;
  position: relative;
}
</style>
