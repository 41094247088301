<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Exception</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-card
                  color="#FFFFFF"
                  class="mb-6">
                  <v-card-title>{{this.form.name}}</v-card-title>
                    <v-card-text>
                      <v-row>Entry: {{formatDate(this.form.entryDateTime)}}</v-row>
                      <v-row>Exit: {{formatDate(this.form.exitDateTime)}}</v-row>
                      <v-row class="mt-3">{{this.form.exceptionDescription}}</v-row>
                    </v-card-text>
                </v-card>
                <v-card 
                  color="#FFFFFF">
                  <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                  >
                    <v-toolbar-title class="text-h6 black--text pl-0">
                      Notes
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-btn
                      color="gray"
                      icon
                      :disabled="!this.editPermissions"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </v-app-bar>
                 
                  <v-card-text>
                    <v-row>
                      <v-textarea  
                      maxlength="2000"
                      v-model= "form.notes"
                      :disabled="!this.editPermissions || this.form.exceptionType == 'None'"
                      >
                      {{this.form.notes}}
                      </v-textarea>
                    </v-row>
                  </v-card-text>
                </v-card>
            </v-col>
            <v-col
              cols="6"
              sm="6"
              md="6"
            >
              <v-row align="center" justify="center">
                <v-btn-toggle
                        v-model="form.exceptionType"
                        rounded
                        dense
                        mandatory
                        class="mt-1"
                      >
                    <v-btn color="primary" class="searchlinks" value="Handled" :disabled="!this.editPermissions || this.form.exceptionType == 'None'">
                      Handled
                    </v-btn>
                    <v-btn color="primary" class="searchlinks" value="Unhandled" :disabled="!this.editPermissions || this.form.exceptionType == 'None'">
                      Unhandled
                    </v-btn>
                  </v-btn-toggle>
              </v-row>
            </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            id="save-btn"
            :disabled="!this.editPermissions || this.form.exceptionType == 'None'"
            color="blue darken-1"
            text
            @click="saveChanges()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { http } from "@/axios";

  export default {
    data: () => ({
      name: 'ExceptionsModal',
      dialog: false,
      form: {
        name: "",
        entryDateTime: "",
        exitDateTime: "",
        notes: "",
        exceptionId: "",
        ingressId: "",
        egressId: "",
        hasException: "",
        exceptionType: "",
        exceptionDescription: "",
        facilityId: "",
      },
      editPermissions: false,
    }),
    methods: {
      formatDate (date) {
        if (!date) return null
        const dDate = new Date(date)
        return dDate.toLocaleDateString('en-us', {year:"numeric", month:"short", day:"numeric", hour:"numeric", minute:"numeric", hour12:false,}) 
      },
      async saveChanges(){
        if (this.form.exceptionId != null){
          const { data } = await http.post(`/api/v2/muster/editException/?facilityId=${this.form.facilityId}`, this.form);
          this.dialog = false
          this.$root.$emit("reloadMuster", "reloading");
        }
        else if (this.form.exceptionId === null){
          const { data } = await http.post(`/api/v2/muster/addException/?facilityId=${this.form.facilityId}`, this.form);
          this.dialog = false
          this.$root.$emit("reloadMuster", "reloading");
        }
      },
    },
    watch: {
    // watch the form to modify the edit permission value
    // this.form.facilityId is empty on mount, so watch it
    // and disabled the save button if the facilityId is not
    // included in the facilityIds you have permission for
    form(newForm, oldForm) {
      if (!isNaN(newForm.facilityId)) {
        this.editPermissions = !!this.$store.state.permissionsByFacility.EditMusterExceptions.includes(this.form.facilityId);
      } else {
        this.editPermissions = false;
      }
    }
    },
    mounted() {}
  }
</script>
